import { Button } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import color from '../../colors.json'
import Input from '../../components/input'
import Loading from '../../components/loading'
import useAuth from '../../hooks/auth'

const Background = styled.div`
  background-image: url(${(props: {src: string}) => props.src});
  background-size: cover;
  background-color: ${color.primary};
  display: flex;
  flex: 1;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 100px;
  margin-bottom: 30px;
  margin-top: 20px;
`

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 300px;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 5px 5px 36px 5px rgba(0,0,0,0.24);
`

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const auth = useAuth()
  const navigate = useNavigate()

  const login = (): void => { 
    setShowError(false)
    setIsLoading(true)
    auth.login({ email, password }).then(() => {
      navigate('/map')
    }).catch((e) => {
      console.log('login error', e);
      setIsLoading(false)
      setShowError(true)
    })
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'password') {
      setPassword(e.target.value)
    }
  }

  return (
    <Background src='/lines.svg'>
      <LoginContainer>
        <Logo src='logo.jpg' />
        <p style={{height: 15}}>{showError && 'Fel email eller lösenord' }</p>
        <Input type='email' value={email} name='email' placeholder='Email' onChange={handleChange} />
        <Input type='password' value={password} name='password' placeholder='Password' onChange={handleChange} onEnterPress={login} />
        <div style={{height: 36}}>
          { 
            isLoading ? <div style={{paddingTop: 6}}><Loading /></div>
            :  
            <Button onClick={login}>Logga In</Button>
          }
          </div>
      </LoginContainer>
    </Background>
  )
}

export default LoginPage
