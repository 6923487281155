import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login/login";
import useAuth from "./hooks/auth";
import Loading, { LoadingBox } from "./components/loading";
import axios from "axios";
import styled from "styled-components";
import Navbar from "components/navbar";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const UsersPage = React.lazy(() => import("./pages/users/users"));
const UserPage = React.lazy(() => import("./pages/user/user"));
const ProjectsPage = React.lazy(() => import("./pages/projects/projects"));
const ProjectPage = React.lazy(() => import("./pages/project/project"));
const LayersPage = React.lazy(() => import("./pages/layers/layers"));
const LayerPage = React.lazy(() => import("./pages/layer/layer"));
const Map = React.lazy(() => import("./pages/map/map"));
const ShapesPage = React.lazy(() => import("./pages/shapes/shapes"));
const ShapePage = React.lazy(() => import("./pages/shape/shape"));
const MeasurementPage = React.lazy(() => import("./pages/measurement/measurement"));
const ListsPage = React.lazy(() => import("./pages/lists/lists"));
const ListPage = React.lazy(() => import("./pages/list/list"));

const AppContainter = styled.div`
  display: flex;
  width: 100%;
`

const App: React.FC = () => {
  axios.defaults.baseURL = process.env.REACT_APP_URL
  return <AppContainter>
    <ToastContainer 
      position="bottom-right"
      autoClose={7000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light" />
    <Routing />
  </AppContainter>
};

const Routing: React.FC = () => {
  const auth = useAuth();

  // fick otroligt mycket problem här och kommer ändast använda den här så satt any för ingen custom data kommer hamna igenom den
  const RequireAuth: React.FC<any> = ({ children, noNavbar }) => {
    if (auth.authed) {
      if(noNavbar) return children
      return <>
        <Navbar /> 
        {children}
      </>;
    } else {
      return <Navigate to="/" />;
    }
  };

  const ReverseAuth: React.FC<any> = ({ children }) => {
    if (auth.authed) {
      return <Navigate to="/users" />;
    } else {
      return children;
    }
  };

  return (
    <Suspense fallback={<LoadingBox style={{ minHeight: '50vh' }} />}>
      <Routes>
        <Route
          path="/"
          element={
            <ReverseAuth>
              <LoginPage />
            </ReverseAuth>
          }
        />
      
        <Route
          path="/map"
          element={
            <RequireAuth noNavbar>
              <Map />
            </RequireAuth>
          }
        />
        <Route
          path="/users/:id"
          element={
            <RequireAuth>
              <UserPage />
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <UsersPage />
            </RequireAuth>
          }
        />
        <Route
          path="/projects/:id"
          element={
            <RequireAuth>
              <ProjectPage />
            </RequireAuth>
          }
        />
        <Route
          path="/projects"
          element={
            <RequireAuth>
              <ProjectsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/layers/:layerId"
          element={
            <RequireAuth>
              <LayerPage />
            </RequireAuth>
          }
        />
        <Route
          path="/layers/"
          element={
            <RequireAuth>
              <LayersPage />
            </RequireAuth>
          }
        />
        <Route
          path="/shapes/"
          element={
            <RequireAuth>
              <ShapesPage />
            </RequireAuth>
          }
        />
        <Route
          path="/shapes/:id"
          element={
            <RequireAuth>
              <ShapePage />
            </RequireAuth>
          }
        />
        <Route
          path="/lists"
          element={
            <RequireAuth>
              <ListsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/lists/:listId"
          element={
            <RequireAuth>
              <ListPage />
            </RequireAuth>
          }
        />
        <Route
          path="/measurements/:id"
          element={
            <RequireAuth>
              <MeasurementPage />
            </RequireAuth>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default App;
