import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginData } from "../api/auth";
import jwtDecode from "jwt-decode";
import { UserType } from "shared/types/users";
import { toast } from "react-toastify";

interface JWT {
  userId: string;
  role: string;
  iat: number;
  exp: number;
}

const useAuth = () => {
  const [authed, setAuthed] = useState(localStorage.getItem("token") !== null);
  const [me, setMe] = useState<UserType | null>(null)

  const navigate = useNavigate();

  useEffect(() =>  {
    // On refresh, me will be lost but token still there. Gotta refetch me
    authed && getMe().catch(() => toast.error('Kunde inte hämta användare'))
  }, [])

  const token = localStorage.getItem("token");
  if (token !== null) {
    const tokenData: JWT = jwtDecode(token);
    if (tokenData.exp < (new Date().getTime() + 1) / 1000) {
      
      localStorage.removeItem("token");
      setAuthed(false);
      navigate("/");
    } else {
      axios.defaults.headers.common.Authorization = "Bearer " + token;
    }
  }

  const setToken = (token: string): void => {
    localStorage.setItem("token", token);
    axios.defaults.headers.common.Authorization = "Bearer " + token;
  };

  const removeToken = (): void => {
    localStorage.removeItem("token");
    axios.defaults.headers.common.Authorization = "";
  };

  const getMe = async () => {
    const meRes = await axios.get('/users/@me')
    setMe(meRes.data)
  }
  return {
    authed, me,
    async login(data: LoginData) {
      const res = await axios.post("/auth/login", data)
      setToken(res.data.token) // gotta set this before getting me
      await getMe() 
      setAuthed(true)
    },
    logout() {
      removeToken();
      setAuthed(false);
      navigate("/");
    },
    checkAuth() {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthed(true);
      } else {
        setAuthed(false);
        navigate("/");
      }
    },
  };
};

export interface AuthProviderProps {
  children: React.ReactNode;
}

const defaultData = {
  authed: false,
  login: async (data: LoginData) => {
    console.log("login");
  },
  logout: () => {},
  checkAuth: () => {},
  me: null
};

interface AuthDataType {
  authed: boolean,
  login: (data: LoginData) => Promise<void>,
  logout: () => void
  checkAuth: () => void,
  me: null | UserType
}

export const authContext = createContext<AuthDataType>(defaultData);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const AuthConsumer = () => useContext(authContext);

export default AuthConsumer;
