import styled from "styled-components";

export interface InputProps {
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterPress?: () => void;
  type: string;
  name: string;
  value?: string | number;
  placeholder?: string;
  checked?: boolean
  defaultChecked?: boolean;
  style?: React.CSSProperties;
  accept?: string;
}

const StyledInput = styled.input`
  border: 1px solid #ccc;
  border-radius: var(--borderRadius);
  padding: 8px;
  margin-top: 8px;
  box-sizing: border-box;
  width: 100%;
`;

const Input: React.FC<InputProps> = ({
  type,
  onChange,
  name,
  placeholder,
  value,
  style,
  defaultChecked,
  checked,
  accept,
  onEnterPress
}) => {
  return type === "checkbox" && defaultChecked !== undefined ? (
    <StyledInput
      type={type}
      name={name}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      style={style}
      defaultChecked={defaultChecked}
      accept={accept}
    />
  ) : (
    <StyledInput
      type={type}
      name={name}
      checked={checked}
      onChange={(e) => onChange(e)}
      onKeyDown={e => e.key === 'Enter' && onEnterPress && onEnterPress()}
      placeholder={placeholder}
      style={style}
      value={value}
      accept={accept}
    />
  );
};

export default Input;
