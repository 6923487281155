import React from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import colors from '../colors.json'

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LoadingBox: React.FC<{ size?: number, style?: object }> = ({ size, style }) => {
  style = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', ...style }
  return <Loading size={size} style={style} />
}

const Loading: React.FC<{ size?: number, style?: object }> = ({ size, style = {} }) => {
  return (
    <Center style={style}>
      <ClipLoader color={colors.primary} size={size} />
    </Center>
  )
}

export default Loading
