import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/auth'
import colors from '../colors.json'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import MapIcon from '@mui/icons-material/Map'
import GroupIcon from '@mui/icons-material/Group'
import LayersIcon from '@mui/icons-material/Layers'
import FolderIcon from '@mui/icons-material/Folder'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocationOnIcon from '@mui/icons-material/LocationOn'
import UturnIcon from '@mui/icons-material/UTurnLeft'
import LogoutIcon from '@mui/icons-material/Logout'
import { Typography } from '@mui/material'

const drawerWidth = 240

export const navbarHeight = 50

// const Logo = styled.img`
//   width: 100px;
//   margin-bottom: 30px;
// `

const Spacer = styled.div`
  display: flex;
  flex: 1;
`

const NavButton: React.FC<{text: string, path: string | -1, onClick?: () => void}> = ({ children, text, path, onClick }) => {
  const navigate = useNavigate()
  const color = window.location.href.includes(String(path).toLowerCase()) ? colors.primary : '#000'

  const handleClick = (): void => {
    if (onClick != null) {
      onClick()
    } else {
      if (path === -1) {
        navigate(path)
      } else {
        navigate(path)
      }
    }
  }

  return (
    <ListItem key={text} disablePadding onClick={handleClick}>
      <ListItemButton>
        <ListItemIcon>
          {children}
        </ListItemIcon>
        <ListItemText style={{ color }} primary={text} />
      </ListItemButton>
    </ListItem>
  )
}

const Navbar: React.FC = ({ children }) => {
  const { authed, logout, me } = useAuth()
  return (
    <>
      {(
         <Drawer 
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          open
          variant='permanent'
          anchor='left'
      > 
          <Toolbar />
          {/* <Center>
            <Logo src='logo.jpg' />
          </Center> */}
          <Typography style={{ margin: 20 }} variant='h5'>Svensk Naturförvaltning</Typography>
          <Divider />
          <List>
            <NavButton text='Karta' path='/map'><MapIcon /></NavButton>
            <NavButton text='Användare' path='/users'><GroupIcon /></NavButton>
            <NavButton text='Lager' path='/layers'><LayersIcon /></NavButton>
            <NavButton text='Projekt' path='/projects'><FolderIcon /></NavButton>
            <NavButton text='Former' path='/shapes'><LocationOnIcon /></NavButton>
            { authed && me && me!.role !== 'GUEST' && <NavButton text='Listor' path='/lists'><FormatListBulletedIcon /></NavButton>}
            <NavButton text='Tillbaka' path={-1}><UturnIcon style={{ transform: 'rotate(90deg)' }} /></NavButton>
          </List>
          <Divider />
          <List style={{ padding: '0 20px' }}>
            {children}
          </List>
          <Spacer />
          <List>
            <NavButton text='Logga ut' path='' onClick={() => logout()}><LogoutIcon /></NavButton>
          </List>
          <Divider />
          </Drawer>
      )}
    </>
  )
}

export default Navbar
